































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class ShujuchiJibenxinxi extends Vue {
  @Prop()
  private ifVisible: any;
  @Prop()
  private patient: any;
  private get visible() {
    return this.ifVisible;
  }
  private set visible(val) {
    this.$emit("update:ifVisible", val);
  }
  private cancel() {
    this.$emit("update:ifVisible", false);
  }
  private save() {
    this.$emit("update:ifVisible", false);
  }
}
