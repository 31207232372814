






import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop({ type: String, default: "暂无数据" })
  private text: any;
}
